<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader> {{ title }} </CCardHeader>
        <CCardBody>
          <CForm>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput invalid description="" label="الاسم" horizontal autocomplete="name" v-model.trim="form.name"
                  invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.name.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CTextarea invalid label="الوصف" rows="3" horizontal v-model.trim="form.description" text=""
                  invalid-feedback="يوجد خطأ في هذا الحقل" :is-valid="!$v.form.description.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput invalid description="" label="العنوان" horizontal autocomplete="address"
                  v-model.trim="form.address" invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.address.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput invalid description="" type="number" label="الهاتف" horizontal autocomplete="phone"
                  v-model.trim="form.phone" invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.phone.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput invalid description="" type="number" label="هاتف اخر (اختياري)" horizontal autocomplete="phone_2"
                  v-model.trim="form.phone_2" invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.phone_2.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9">
                <CInput invalid description="" type="email" label="الايميل" horizontal autocomplete="email"
                  v-model.trim="form.email" invalid-feedback="يوجد خطأ في هذا الحقل"
                  :is-valid="!$v.form.email.$anyError" />
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3">الاقسام</label>
                  <CCol sm="9">
                    <v-select :multiple="true" :options="subcategories" v-model="form.subcategories"
                      :reduce="(subcategory) => subcategory.id" dir="rtl" label="name" placeholder="اختار الاقسام">
                      <template> عذراً هذه الاقسام غير موجود </template>
                      <template v-if="$v.form.subcategories.$anyError" #footer>
                        <div style="opacity: 0.8" class="text-danger mt-2">
                          يجب عليك اختيار الاقسام
                        </div>
                      </template>
                    </v-select>
                  </CCol>
                </div>
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3">المدينة</label>
                  <CCol sm="9">
                    <v-select :options="cities" v-model="form.city_id" :reduce="(city) => city.id" dir="rtl" label="name"
                      placeholder="اختار المدينة">
                      <template> عذراً هذه المدينة غير موجود </template>
                      <template v-if="$v.form.city_id.$anyError" #footer>
                        <div style="opacity: 0.8" class="text-danger mt-2">
                          يجب عليك اختيار المدينة
                        </div>
                      </template>
                    </v-select>
                  </CCol>
                </div>
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> الصورة </label>
                  <CCol sm="12" md="9">
                    <VueFileAgent ref="vueFileAgent" :theme="'grid'" :multiple="false" :deletable="true" :meta="true"
                      :accept="'image/*'" :maxSize="'3MB'" :maxFiles="1" :helpText="'اختار صورة او اسحب الى هنا'"
                      :errorText="{
                        type: 'نوع الملف غير صالح . الملفات من نوع صور فقط التي يتم قبولها',
                        size: 'حجم الملف اكبر من ٣ ميجا',
                      }" @select="filesSelected($event)" @beforedelete="onBeforeDelete($event)"
                      @delete="fileDeleted($event)"></VueFileAgent>
                  </CCol>
                  <CCol sm="12" md="3">
                    <CImg :src="form.image_url" fluid width="150px" height="150px" />
                  </CCol>
                  <div class="mt-3 text-danger" v-if="$v.form.image.$anyError">
                    <span>يوجد خطأ في الصورة</span>
                  </div>
                </div>
              </CCol>
            </CRow>

            <CRow class="mt-5">
              <CCol sm="12" md="9">
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> حالة الخدمة </label>
                  <CCol sm="9">
                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input type="radio" name="category_id" class="form-check-input" value="1" v-model="form.status" />
                        نشط
                      </label>
                    </div>

                    <div role="group" class="form-check form-check-inline">
                      <label>
                        <input type="radio" name="category_id" class="form-check-input" value="0" v-model="form.status" />
                        متوقف
                      </label>
                    </div>
                  </CCol>
                </div>
              </CCol>
            </CRow>

            <CRow class="mt-3">
              <CCol sm="12" md="9" class="map-wrapper">
                <CInput id="search" horizontal />
                <GmapMap ref="gmap" :center="mapCenter" :zoom="zoomLevel" style="width: 100%; height: 500px" @click="mapClicked">
                  <GmapMarker :position="markerPosition"></GmapMarker>
                </GmapMap>
              </CCol>
            </CRow>


          </CForm>
        </CCardBody>
        <CCardFooter class="mt-5">
          <CButton :disabled="loading" @click="pushData" type="button" color="success" size="lg">
            <CSpinner v-if="loading" color="white" size="sm" />
            <span v-else>
              <CIcon name="cil-save" /> حفظ
            </span>
          </CButton>
        </CCardFooter>
        <CCardFooter v-if="formErrors">
          <CAlert color="danger">
            <CListGroup flush>
              <CListGroupItem v-for="(err, index) in formErrors" :key="index">
                {{ err[0] }}
              </CListGroupItem>
            </CListGroup>
          </CAlert>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
export default {
  name: "Form",
  props: {
    title: {
      type: String,
      default: "اضافة خدمة جديدة",
    },
    formErrors: {
      type: Object,
      default: null,
    },
    cities: {
      type: Array,
      default: () => [],
    },
    subcategories: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      markerPosition: null,
      searchBox: null,
      zoomLevel: 5,
      mapCenter: { lat: 26.8206, lng: 30.8025 },
      fileRecords: [],
      fileRecordsForUpload: [],
      form: {
        name: "",
        description: "",
        phone: null,
        phone_2: null,
        email: null,
        address: "",
        latitude: null,
        longitude: null,
        city_id: null,
        image: null,
        subcategories: [],
        status: 1,
        social_links: [],
      },
    };
  },
  validations() {
    return {
      form: this.validationRules
    }
  },
  mounted() {
    this.waitForGoogleMaps();
    if (this.formData) {
      this.form = this.formData
      this.form.subcategories = this.formData.subcategories_ids
      this.mapCenter = {
        lat: Number(this.formData.latitude),
        lng: Number(this.formData.longitude),
      };
      this.markerPosition = this.mapCenter;
    }
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    pushData() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return true;
      }

      this.$emit("saveData", this.form);
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      const reader = new FileReader();
      reader.onloadend = () => {
        this.form.image = reader.result;
      };
      reader.readAsDataURL(validFileRecords[0].file);
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
    },
    deleteUploadedFile: function (fileRecord) {
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    fileDeleted: function () {
      this.form.image = null;
    },

    mapClicked(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.form.latitude = event.latLng.lat();
      this.form.longitude = event.latLng.lng();
    },
    waitForGoogleMaps() {
      if (typeof google !== 'undefined') {
        // Google object is available
        this.initAutocomplete();
      } else {
        // Retry after some time
        setTimeout(this.waitForGoogleMaps, 300);
      }
    },
    initAutocomplete() {
      const input = document.getElementById('search');
      this.searchBox = new this.google.maps.places.Autocomplete(input);

      this.searchBox.addListener('place_changed', () => {
        const place = this.searchBox.getPlace();
        if (!place.geometry) {
          return;
        }
        // Animate the map to the new center
        this.animateMapToLocation(place.geometry.location);
        this.mapCenter = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        this.markerPosition = this.mapCenter;
        this.form.latitude = this.mapCenter.lat;
        this.form.longitude = this.mapCenter.lng;
      });
    },
    animateMapToLocation(location) {

      let interval;
      const duration = 1000; // Duration of the animation in milliseconds
      const steps = 10; // Number of steps in the animation
      const stepTime = duration / steps;
      let currentStep = 0;

      interval = setInterval(() => {
        currentStep++;
        if (currentStep > steps) {
          clearInterval(interval);
        } else {
          const lat = this.mapCenter.lat + (location.lat() - this.mapCenter.lat) * currentStep / steps;
          const lng = this.mapCenter.lng + (location.lng() - this.mapCenter.lng) * currentStep / steps;
          this.mapCenter = { lat, lng };
        }
      }, stepTime);

      this.zoomLevel = 22;
    },


  },
};
</script>
